<template>
  <div>
    <b-card>
      <h2>Podstawowe informacje:</h2>

      <div class="form-group">
        <label for="title">Nazwa (To pole wyświetla się w stronie twojego profilu): </label>
        <input
          id="title"
          v-model="business.name"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="title">NIP: </label>
        <input
          id="title"
          v-model="business.NIP"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="adress">Adres: </label>
        <input
          id="adress"
          v-model="business.address"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="title">Strona internetowa (To pole wyświetla się w stronie twojego profilu): </label>
        <input
          id="title"
          v-model="business.website"
          type="text"
          class="form-control"
        >
      </div>

    </b-card>
    <b-card>
      <h2>Linki: </h2>

      <div
        v-for="link in links"
        :key="link"
      >
        <div class="form-group">
          <input
            id="title"
            v-model="link.name"
            type="text"
            class="form-control"
            placeholder="Wpisz tytuł linku (Będzie się on wyświetlał na twoim profilu)"
          >
          <input
            id="title"
            v-model="link.link"
            style="margin-top: 10px;"
            type="text"
            class="form-control"
            placeholder="Wpisz link (Będzie się on wyświetlał na twoim profilu)"
          >
          <button
            class="btn btn-danger"
            style="padding: 5px; width: 100%; margin-top: 5px;"
            @click="deletelink(link)"
          >
            Usuń link
          </button>
          <hr>
        </div>
      </div>
      <div class="form-group">
        <button
          class="btn btn-primary"
          style="margin-bottom: 0; width: 100%;"
          @click="addLink()"
        >
          Dodaj link
        </button>
      </div>
    </b-card>

    <b-card
      v-if="business.name"
      class="text-center"
    >
      <h2>
        Kod QR do twojego profilu:
      </h2>
      <QrcodeVue :value="`https://pickmode.pl/b/${business.name}`" />
      <div
        class="btn btn-primary mt-2"
        @click="dowloadQrCode()"
      >
        Pobierz kod QR
      </div>
    </b-card>

    <b-card>
      <h2>Zdjęcie profilowe: </h2>

      <div>
        Twoja wizytówka jest dostępna pod adresem: <a
          :href="`https://pickmode.pl/b/${business.name}`"
          target="_blank"
        >https://pickmode.pl/b/{{ business.name }}</a>
      </div>

      <div class="text-center">
        <b-img
          v-if="business.image"
          :src="business.image"
          fluid
          class="text-center my-1 rounded business-image"
        />
      </div>

      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Wybierz zdjęcie"
        drop-placeholder="Upuść plik tutaj..."
      />
    </b-card>

    <b-card>
      <div>
        <div class="custom-control custom-switch">
          <input
            id="customSwitch1"
            v-model="business.is_accepting_new_customers"
            type="checkbox"
            class="custom-control-input"
          >
          <label
            class="custom-control-label"
            for="customSwitch1"
          >Czy akceptujesz nowych klientów?</label>
        </div>
      </div>
    </b-card>

    <b-card>
      <button
        class="btn btn-success dodaj"
        style="width:100%;"
        @click="process()"
      >
        Edytuj profil firmy
      </button>
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormFile, BImg } from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'OfferAdd',
  components: {
    BCard,
    BFormFile,
    BImg,
    QrcodeVue,
  },
  data() {
    return {
      offer: {},
      categories: [],
      errors: [],
      business: {},
      links: [],
      file: null,
      qrcode: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      document.title = 'Ustawienia biznesu - Pickmode'

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.get(`${process.env.VUE_APP_API_URL}my-business`, config)
        .then(response => {
          this.business = response.data
          this.links = response.data.business_links
        })
        .catch(() => {
          swal.fire({
            title: 'Błąd',
            text: 'Nie udało się pobrać danych',
            type: 'error',
            confirmButtonText: 'OK',
          })
        })
    },
    dowloadQrCode() {
      const link = document.createElement('a')
      link.download = 'Kod qr twojego biznesu.png'
      link.href = document.querySelector('canvas').toDataURL()
      link.click()
    },
    process() {
      swal.fire({
        title: 'Czy jesteś pewien?',
        text: 'Zmiany zostaną zapisane',
        icon: 'warning',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, zapisz!',
      }).then(result => {
        if (result.value) {
          const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
          const formData = new FormData()
          formData.append('image', this.file)
          formData.append('name', this.business.name)
          formData.append('NIP', this.business.NIP)
          formData.append('website', this.business.website)
          formData.append('business_links', JSON.stringify(this.links))
          formData.append('is_accepting_new_customers', this.business.is_accepting_new_customers)
          formData.append('adress', this.business.address)

          axios.post(`${process.env.VUE_APP_API_URL}edit-my-business`, formData, config)
            .then(() => {
              swal.fire({
                title: 'Sukces',
                text: 'Pomyślnie zapisano zmiany',
                type: 'success',
                icon: 'success',
                confirmButtonText: 'OK',
              })

              this.init()
            })
            .catch(error => {
              swal.fire({
                title: 'Error',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
              })
            })
        }
      })
    },
    addLink() {
      // add link to array
      this.links.push({
        name: '',
        link: '',
      })
    },
    deletelink(link) {
      this.links.splice(this.links.indexOf(link), 1)
    },
  },
}
</script>

<style>
.business-image {
  height: 150px;
}
</style>
